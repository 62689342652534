<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import ProductAreaTable from './product_area_table.vue';

formCreate.component('productAreaTable', ProductAreaTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),

  },
  props: {
    parentCode: String,
    formType: String,
    functionName: String,
    field: String,
    titleName: String,
    value: {
      type: Object,
      default: () => ({
        product: [],
        productLevel: [],
      }),
    },
  },
  watch: {
    modalChildren: {
      deep: true,
      immediate: true,
      handler(n) {
        console.log(n);
      },
    },
  },
  data() {
    return {
      rule: [],
      configureData: null,
      buttons: {
        submit: false,
        close: false,
      },
    };
  },

  mounted() {
    this.getFormRule(this.functionName, this.parentCode);
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'modalTitle') {
        rowData.props = {
          title: this.titleName || rowData.title,
        };
      }
      rowData.props = {
        ...rowData.props,
        parentCode: this.parentCode,
        isView: this.formType === 'view',
      };
      rowData.value = this.value;
      return rowData;
    },
    getFormData() {
      const goods = [];
      const product = this.getFieldValue('modalChildren').product || [];
      const productLevel = this.getFieldValue('modalChildren').productLevel || [];
      product.forEach((v) => {
        goods.push({
          ...v,
          id: '',
          goodsName: v.productName,
          goodsCode: v.productCode,
          productLevelFlag: 'N',
        });
      });
      productLevel.forEach((v) => {
        goods.push({
          ...v,
          id: '',
          productLevelFlag: 'Y',
        });
      });
      const data = {
        dimension: this.getFieldValue('dimension') || 1,
        type: this.getFieldValue('type') || 1,
        goods,
      };
      if (this.configureData) {
        data.id = this.configureData.id || '';
      }
      return data;
    },
    setFormValue(val, data) {
      this.configureData = {
        ...val[0].result,
      };
    },
    formComplete() {
      // this.setValue({
      //   dimension: 1,
      //   type: 1,
      // });
      if (this.configureData) {
        const product = [];
        const productLevel = [];
        this.configureData.goods.forEach((v) => {
          const rowData = v;
          if (v.productLevelFlag === 'Y') {
            productLevel.push(rowData);
          } else {
            rowData.productName = rowData.goodsName;
            rowData.productCode = rowData.goodsCode;
            product.push(rowData);
          }
        });
        this.setValue({
          modalChildren: {
            product,
            productLevel,
          },
        });
      }
    },
  },
};
</script>
