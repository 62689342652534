<script>
// import request from '@/found/utils/request';
import TargetTable from '../target_two/form/form.vue';

export default {
  extends: TargetTable,
  props: {
    value: Object,
    field: String,
    paramsExt1: String,
    isView: Boolean,
  },
  data() {
    return {
      configureData: null,
    };
  },
  components: {

  },
  mounted() {
  },
  methods: {
    setRule(v) {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
        disabled: this.isView,
      };
      return rowData;
    },
    formComplete() {
      this.$nextTick(() => {
        this.hiddenFields(true, ['objectType', 'contractCode', 'type']);
        this.$emit('setParentData');
      });
    },
    getSalesType(paramsExt1) {
      this.targetChange(paramsExt1);
    },
    setInfoValue(result) {
      this.setValue({
        ...result,
        taskType: result.taskType ? result.taskType.toString() : '',
      });
      this.salesArea = result.salesAreaCode;
      this.tableData = [];
      result.saleGoalItemVo.forEach((v) => {
        this.tableData.push({
          ...v.saleGoalItemVo,
          targetNum: v.targetNum,
          targetYear: v.targetYear,
          productCode: v.goodsCode,
          productName: v.goodsName,
          productLevelCode: v.productLevelCode,
          productLevelName: v.productLevelName,
        });
      });

      // if (result.objectType.toString() === '1') {
      //   this.hiddenFields(false, ['cusCode', 'cusName', 'salesAreaCode']);
      // } else if (result.objectType.toString() === '2') {
      //   this.hiddenFields(false, ['orgCode', 'orgName']);
      // } else if (result.objectType.toString() === '3') {
      //   this.hiddenFields(false, ['terminalCode', 'terminalName']);
      // }
      this.getRule('saleGoalItemVo').props.tableData = this.tableData;
      this.getRule('saleGoalItemVo').props.targetType = result.type ? result.type.toString() : '';
      this.getRule('saleGoalItemVo').props.taskType = result.taskType ? result.taskType.toString() : '';
      this.getRule('saleGoalItemVo').props.edit = this.isView ? 'view' : 'edit';
      this.getRule('saleGoalItemVo').props.contractEdit = true && !this.isView;
      this.getRule('salesAreaCode').props.salesAreaCode = result.salesAreaCode;
    },
  },

};
</script>
