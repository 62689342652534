<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import SalesVolumeTable from './sales_volume_table.vue';

formCreate.component('salesVolumeTable', SalesVolumeTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),

  },
  props: {
    parentCode: String,
    formType: String,
    functionName: String,
    field: String,
    titleName: String,
    paramsExt1: String,
    value: {
      type: Object,
      default: () => ({
        product: [],
        productLevel: [],
      }),

    },
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
      },
    };
  },

  mounted() {
    // this.beforeSetRule(this.rule);
    this.getFormRule(this.functionName, this.parentCode);
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'modalTitle') {
        rowData.props = {
          title: this.titleName || rowData.title,
        };
      }
      rowData.props = {
        ...rowData.props,
        parentCode: 'CRM20210203000000866',
        paramsExt1: this.paramsExt1,
        field: rowData.field,
        isView: this.formType === 'view',
      };
      rowData.on = {
        setParentData: this.setParentData,
      };
      return rowData;
    },
    getFormData() {
      const data = this.getVolumeData(this.$refs.formCreate);
      return data;
    },
    getVolumeData(form) {
      let canSubmit = true;
      let data = null;
      for (const i in form.$refs) {
        if (form.$refs[i] && form.$refs[i].$refs.formCreate) {
          if (!this.$refs.formCreate.$refs[i].getFormData()) {
            return canSubmit = false;
          }
          // console.log(form.$refs[i].getFormData());
          data = form.$refs[i].setTabData();
        }
      }

      return data;
      // return canSubmit;
    },
    setFormValue(val, data) {
      const rowData = val;
      this.configureData = {
        ...rowData[0],
        saleGoalItemVo: val,
      };
    },
    setParentData() {
      const form = this.$refs.formCreate;
      for (const i in form.$refs) {
        if (form.$refs[i] && form.$refs[i].$refs.formCreate) {
          const { field } = this.$refs.formCreate.$refs[i];
          if (this.configureData) {
            this.fApi.method(field, 'setInfoValue')(this.configureData);
          }

          this.fApi.method(field, 'getSalesType')(this.paramsExt1);
          this.fApi.method(field, 'setValue')({
            type: this.paramsExt1,
            objectType: 1,
          });
        }
      }
    },
    // formComplete() {

    //   const form = this.$refs.formCreate;
    //   for (const i in form.$refs) {
    //     if (form.$refs[i] && form.$refs[i].$refs.formCreate) {
    //       form.$refs[i].setParentValue(this.configureData, this);
    //     }
    //   }
    // },
  },
};
</script>
