<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import SaleAreaTable from './sale_area_table.vue';

formCreate.component('saleAreaTable', SaleAreaTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),

  },
  props: {
    parentCode: String,
    formType: String,
    functionName: String,
    field: String,
    titleName: String,
    value: {
      type: Object,
      default: () => ({
        salesAreas: [],
        salesAreasNoContain: [],
      }),
    },
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
      },

      modalChildren: {},
    };
  },

  mounted() {
    this.getFormRule(this.functionName, this.parentCode);
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'modalTitle') {
        rowData.props = {
          title: this.titleName || rowData.title,
        };
      } else {
        this.value[rowData.field] = [];
      }
      if (rowData.field === 'salesAreas') {
        rowData.on = {
          ...rowData.on,
          setSalesArea: this.setSalesArea,
        };
      }
      if (rowData.field === 'salesAreasNoContain') {
        rowData.on = {
          ...rowData.on,
          setSalesArea: this.setSalesAreasNoContain,
        };
      }
      rowData.props = {
        ...rowData.props,
        isView: this.formType === 'view',
        areas: [],
      };
      return rowData;
    },
    getFormData() {
      const data = {
        voList: [],
      };
      const salesAreas = this.getFieldValue('salesAreas') || [];
      const salesAreasNoContain = this.getFieldValue('salesAreasNoContain') || [];
      salesAreas.forEach((v) => {
        data.voList.push(v);
      });
      salesAreasNoContain.forEach((v) => {
        data.voList.push(v);
      });
      return data;
    },
    // 选多销售区域
    setSalesArea(val) {
      val.map((v) => {
        const rowData = v;
        rowData.containFlag = 1;
        return rowData;
      });
      const salesAreasNoContain = this.getFieldValue('salesAreasNoContain');
      // const modalChildren = {
      //   salesAreasNoContain,
      //   salesAreas: val,
      // };
      this.setValue({
        salesAreas: val,
        // modalChildren,
      });
    },
    setSalesAreasNoContain(val) {
      val.map((v) => {
        const rowData = v;
        rowData.containFlag = 0;
        return rowData;
      });
      const salesAreas = this.getFieldValue('salesAreas');
      // const modalChildren = {
      //   salesAreasNoContain: val,
      //   salesAreas,
      // };
      this.setValue({
        salesAreasNoContain: val,
        // modalChildren,
      });
    },
    setFormValue(val, data) {
      const salesAreas = [];
      const salesAreasNoContain = [];
      val[0].voList.forEach((v) => {
        if (v.containFlag === 1) {
          salesAreas.push(v);
        } else {
          salesAreasNoContain.push(v);
        }
      });
      this.configureData = {
        salesAreasNoContain,
        salesAreas,
      };
    },
    formComplete() {
      if (this.configureData) {
        this.setValue({
          salesAreas: this.configureData.salesAreas,
          salesAreasNoContain: this.configureData.salesAreasNoContain,
        });
        this.getRule('salesAreas').props.areas = this.configureData.salesAreas;
        this.getRule('salesAreasNoContain').props.areas = this.configureData.salesAreasNoContain;
      }
    },
    // formComplete() {
    //   // console.log(this.value, '=========');
    //   // this.setValue(this.value || this.modalChildren);
    //   // this.getRule('salesAreas').props.areas = [{
    //   //   province: '',
    //   //   city: '',
    //   //   district: '',
    //   //   provinceCode: '',
    //   //   cityCode: '',
    //   //   districtCode: '',
    //   // }];
    //   // this.getRule('salesAreasNoContain').props.areas = [{
    //   //   province: '',
    //   //   city: '',
    //   //   district: '',
    //   //   provinceCode: '',
    //   //   cityCode: '',
    //   //   districtCode: '',
    //   // }];
    // },
  },
};
</script>
