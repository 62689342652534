<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import BaseInfoCustomer from './base_info_customer.vue';

formCreate.component('baseInfoCustomer', BaseInfoCustomer);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    parentCode: String,
    functionName: String,
    field: String,
    value: Object,
    titleName: String,
    formType: String,
  },
  data() {
    return {
      rule: [],
      formValue: {},
      parentNeed: true,
      buttons: {
        submit: false,
        close: false,
      },
    };
  },

  mounted() {
    this.getFormRule(this.functionName, this.parentCode);
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'modalTitle') {
        rowData.props = {
          title: this.titleName || rowData.title,
        };
      }
      if (rowData.field === 'cusName') {
        rowData.on = {
          ...rowData.on,
          setCusName: this.setCusName,
        };
      } else if (rowData.field === 'endTime' || rowData.field === 'startTime') {
        rowData.props = {
          ...rowData.props,
          relation: {
            beginField: 'startTime',
            endField: 'endTime',
          },
        };
      }
      rowData.props = {
        ...rowData.props,
        disabled: this.formType === 'view' || this.disabledFunc(v),
        parentCode: this.parentCode,
        formType: this.formType === 'view',
      };
      return rowData;
    },
    parentNeedData() {
      const data = this.getFormData();
      return data;
    },
    setFormValue(val, data) {
      this.configureData = {
        ...val,
        ...data,
      };
    },
    formComplete() {
      // 省
      this.setSelect(
        'provinceCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '1' },
        { label: 'regionName', value: 'regionCode' },
      );

      // 市
      this.setSelect(
        'cityCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '2', parentCode: this.configureData ? this.configureData.provinceCode : '' },
        { label: 'regionName', value: 'regionCode' },
      );
      // 区
      this.setSelect(
        'districtCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '3', parentCode: this.configureData ? this.configureData.cityCode : '' },
        { label: 'regionName', value: 'regionCode' },
      );

      this.setValue(this.configureData);
    },
    disabledFunc(data) {
      let isDisabled = false;
      switch (data.field) {
        case 'cusCode': isDisabled = true; break;
        case 'dockingPeople': isDisabled = true; break;
        case 'dockingCode': isDisabled = true; break;
        case 'cusLegalRepresentative': isDisabled = true; break;
        case 'cusContact': isDisabled = true; break;
        case 'channelCode': isDisabled = true; break;
        case 'channelName': isDisabled = true; break;
        case 'orgName': isDisabled = true; break;
        case 'orgCode': isDisabled = true; break;
        case 'province': isDisabled = true; break;
        case 'provinceCode': isDisabled = true; break;
        case 'city': isDisabled = true; break;
        case 'cityCode': isDisabled = true; break;
        case 'district': isDisabled = true; break;
        case 'districtCode': isDisabled = true; break;
        default:
          break;
      }
      return isDisabled;
    },
    // setCusName
    setCusName(val) {
      this.cusInfo = val;
      this.cusName = val.customerName;
      this.cusCode = val.customerCode;

      // 对接人
      this.getRule('dockingCode').props.disabled = false;
      this.setSelect(
        'dockingCode',
        '/mdm/mdmCustomerMsgController/dockingList',
        { customerCode: val.customerCode },
        { label: 'fullName', value: 'userName' },
        { functionCode: 'select-docking' },
      );
      // 省
      this.setSelect(
        'provinceCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '1' },
        { label: 'regionName', value: 'regionCode' },
      );

      // 市
      this.setSelect(
        'cityCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '2', parentCode: val.provinceCode },
        { label: 'regionName', value: 'regionCode' },
      );
      // 区
      this.setSelect(
        'districtCode',
        '/mdm/mdmRegionController/select',
        { regionLevel: '3', parentCode: val.cityCode },
        { label: 'regionName', value: 'regionCode' },
      );

      this.setValue({
        ...val,
        cusCode: val.customerCode,
        cusName: val.customerName,
        cusLegalRepresentative: val.cusLegalRepresentativeative,
        cusContact: val.customerContact,
        cusOrgName: val.orgName,
        cusOrgCode: val.orgCode,
        cusChannelCode: val.channel,
        cusChannelName: val.channelName,
        channelCode: val.channel,
        channelName: val.channelName,
        provinceCode: val.provinceCode,
        cityCode: val.cityCode,
        districtCode: val.districtCode,
        dockingCode: '',
      });
      // const newRule = this.rule;
      // // this.rule = [];
      // this.rule = newRule;
      // this.reload();
    },
    // 设置下拉框状态
    setSelect(fields, restful, params, optionsKey, headers) {
      this.getRule(fields).restful = restful;
      this.getRule(fields).refresh = true;
      this.getRule(fields).restfulParams = params;
      this.getRule(fields).optionsKey = optionsKey;
      this.getRule(fields).headers = headers || {};
    },
  },
};
</script>
