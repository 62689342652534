<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {

  },
  props: {
    parentCode: String,
    functionName: String,
    formType: String,
    field: String,
    value: Object,
    titleName: String,
    context: String,
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
      },
      defaultValue: '<p>1、乙方连续30天内不从甲方进货，或连续3个月未完成甲方下达销售任务的80%，甲方有权终止合同。</p><p>2、乙方若违反本合同规定，从其他方进货的，需向甲方支付违约金（违约为合同保证金的40%，直接从合同保证金中扣除）。</p><p>3、乙如甲乙双方约定为乙方发展团购经销商，所有团购经销商年度总销售额不得超过乙方年度总销售额的60%，如所有团购经销商年度总销售超过以上比例，甲方将按照约定向团购经销商兑现激励政策，同时有权将超额兑付的激励政策从乙方的各项销售激励政策中扣除。</p>',
    };
  },

  mounted() {
    this.getFormRule(this.functionName, this.parentCode);
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'modalTitle') {
        rowData.props = {
          title: this.titleName || rowData.title,
        };
      } else {
        rowData.props = {
          ...rowData.props,
          config: {
            height: 200,
          },
        };
      }
      rowData.props = {
        ...rowData.props,
        disabled: this.formType === 'view',
      };
      return rowData;
    },
    setFormValue(val, data) {
      this.configureData = {
        ...val[0],
      };
    },
    formComplete() {
      if (this.configureData) {
        this.setValue(this.configureData);
      } else {
        this.setValue({
          liabilityForBreach: this.context || this.defaultValue,
        });
      }
    },
  },
};
</script>
