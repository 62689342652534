<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

import BaseInfo from '../../components/base_info.vue';
import BaseInfoCustomer from '../../components/base_info_customer.vue';
import ContractDetail from '../../components/contract_detail.vue';
import ContractBreach from '../../components/contract_breach.vue';
import ProductArea from '../../components/product_area.vue';
import ProductAreaTable from '../../components/product_area_table.vue';
import SalesVolume from '../../components/sales_volume.vue';
import SalesVolumeTable from '../../components/sales_volume_table.vue';
import SaleArea from '../../components/sale_area.vue';
import SaleAreaTable from '../../components/sale_area_table.vue';

formCreate.component('baseInfoComponents', BaseInfo);
formCreate.component('baseInfoCustomer', BaseInfoCustomer);
formCreate.component('contractDetailComponents', ContractDetail);
formCreate.component('contractBreachComponents', ContractBreach);
formCreate.component('productAreaComponents', ProductArea);
formCreate.component('productAreaTable', ProductAreaTable);
formCreate.component('salesVolumeComponents', SalesVolume);
formCreate.component('salesVolumeTable', SalesVolumeTable);
formCreate.component('saleAreaComponents', SaleArea);
formCreate.component('saleAreaTable', SaleAreaTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    selectTemp: {
      type: Object,
      default: () => {},
    },
    formPreview: Array,
  },
  data() {
    return {
      rule: [],
      queryForm: {},
    };
  },

  mounted() {
    if (this.formPreview) {
      this.buttons.submit = false;
      this.setTemplate(this.formPreview);
    } else if (this.formConfig.formType === 'edit' || this.formConfig.formType === 'view') {
      this.getValue();
    } else {
      this.getData();
    }
  },

  methods: {

    beforeSetRule(rule) {
      rule.unshift({
        type: 'baseInfoComponents',
        field: 'baseInfo',
        props: {
          parentCode: 'CRM20210219000000945',
          functionName: 'dms_contract_base_info',
          field: 'baseInfo',
          titleName: '基础信息',
          formType: this.formConfig.formType,
        },
        value: {},
      });
      rule.push({
        field: 'filesVo',
        title: '附件信息',
        type: 'FormUpload',
        props: {
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          accept: '.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx,.png,.jpg',
          disabled: this.formConfig.formType === 'view',
        },
        visible: true,
        width: '24',
        value: [],
      });
      return rule;
    },
    setTemplate(list) {
      list.forEach((v) => {
        this.rule.push({
          type: `${v.ext2}Components`,
          field: v.jsonName,
          props: {
            parentCode: v.pageCode,
            functionName: v.functionCode,
            field: v.jsonName,
            titleName: v.name,
            paramsExt1: v.ext1,
            context: v.context,
            formType: this.formConfig.formType,
          },
          value: {},
        });
        this.queryForm[v.jsonName] = '';
      });
      this.beforeSetRule(this.rule);
    },
    // 获取数据
    getData() {
      const path = '/dms/m/saleContract/findTemplateDetailsByCode';
      const params = {
        templateCode: this.formConfig.templateCode,
      };
      request
        .get(path, params)
        .then((res) => {
          if (res.success) {
            this.templateData = res.result;
            this.setTemplate(res.result.tuples);
          }
        });
    },
    getValue() {
      const path = '/dms/m/saleContract/findContractDetailsByCode';
      const params = {
        contractCode: this.formConfig.code,
      };
      request
        .get(path, params)
        .then((res) => {
          if (res.success) {
            this.templateData = res.result.contractTemplateVo;
            this.setTemplate(res.result.contractTemplateVo.tuples);
            this.$nextTick(() => {
              for (const i in this.$refs.formCreate.$refs) {
              // 判断是否存在子模块，没有的属于formCreate虚拟dom
                if (this.$refs.formCreate.$refs[i] && this.$refs.formCreate.$refs[i].$refs.formCreate) {
                  const { field } = this.$refs.formCreate.$refs[i];
                  if (this.$refs.formCreate.$refs[i].setFormValue) { this.$refs.formCreate.$refs[i].setFormValue(res.result.data[field], res.result); }
                }
              }
              this.setValue({
                filesVo: res.result.filesVo,
              });
            });
          }
        });
    },
    submit() {
      let path = '/dms/m/saleContract/addContract';
      let data = null;
      let parentNeedData = {};
      let canSubmit = true;

      this.rule.forEach((v) => {
        let formData;
        if (v.field !== 'filesVo') {
          formData = this.fApi.method(v.field, 'getFormData')();
          if (this.fApi.method(v.field, 'parentNeedData')) {
            const a = this.fApi.method(v.field, 'parentNeedData')();
            parentNeedData = {
              ...parentNeedData,
              ...a,
            };
          }
          if (!formData) {
            canSubmit = false;
            return false;
          }
          let formModalChildren;
          if (formData && (typeof formData.modalChildren !== 'undefined')) {
            formModalChildren = formData.modalChildren;
          } else if (formData && (typeof formData.modalChildren === 'undefined')) {
            formModalChildren = formData;
          }
          if (formModalChildren) {
            delete formModalChildren.modalTitle;
            data = {
              ...data,
              [`${v.field}`]: [formModalChildren],
            // ...formModalChildren,
            };
          }
        } else {
          parentNeedData = {
            ...parentNeedData,
            filesVo: this.getFormData().filesVo,
          };
        }
      });
      delete data.baseInfo;
      const params = {
        templateCode: this.templateData.code,
        ...parentNeedData,
        data,
      };
      if (this.formConfig.formType === 'edit') {
        params.id = this.formConfig.id;
        path = '/dms/m/saleContract/editContract';
      }
      if (canSubmit) {
        request
          .post(path, params)
          .then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
      }
    },
    close() {
      console.log(123);
    },
    // formComplete() {

    // },
  },
};
</script>

<style lang="less" scoped>
/deep/ .form-create .form-create .el-form-item .el-form-item{
  margin-bottom: 18px !important;
}
</style>
